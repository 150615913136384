const sanitizeUriWithParams = (uri: string) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.set("threadId", uri);
    return `${uri}?${params.toString()}`;
};

export const useGeneral = () => {
    return {
        sanitizeUriWithParams,
    };
};
