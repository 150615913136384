<template>
    <footer class="bg-white fixed bottom-0 w-full">
        <div
            class="mx-auto max-w-7xl px-6 py-6 md:flex md:items-center md:justify-between lg:px-8"
        >
            <div class="w-full flex items-center justify-center">
                <a
                    :href="poweredByLink"
                    target="_blank"
                    class="inline-block ring-2 ring-gray-100 hover:ring-gray-300 rounded-full p-3"
                >
                    <PoweredByTurno v-if="isTurno"></PoweredByTurno>
                    <PoweredByCourserev v-else></PoweredByCourserev>
                </a>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { computed } from "vue";
import PoweredByCourserev from "./icons/PoweredByCourserev.vue";
import PoweredByTurno from "./icons/PoweredByTurno.vue";
import { useCourse } from "@/composables/course";
const { widgetConfig } = useCourse();
const isTurno = computed(() => {
    return widgetConfig?.value?.platform === "turnoai";
});
const poweredByLink = computed(() => {
    return isTurno.value ? "https://www.turno.ai" : "https://www.courserev.ai";
});
</script>
